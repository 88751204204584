import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from '../../atoms/button';
import WistiaVideo from '../../atoms/wistia-video';

const VideoPlayerCard = ({
  wistiaId,
  title,
  description,
  position,
  videoListLength,
  nextVideoLink,
}) => {
  return (
    <div className="w-full flex flex-col md:flex-row text-white">
      <div className="w-full md:w-[65%]">
        <div className="aspect-w-16 aspect-h-9">
          <div>
            <WistiaVideo id={wistiaId} />
          </div>
        </div>
      </div>

      <div
        className={cn(
          'w-full md:w-[35%] flex flex-col justify-start md:justify-between',
          'bg-[#22306F] rounded-br-2xl rounded-bl-2xl md:rounded-tr-2xl md:rounded-bl-none',
          'border-l-1 border-r-1 border-b-1 md:border-t-1 md:border-l-0 border-solid border-white border-opacity-[15%]',
          'pt-3 pb-8 pl-3 pr-3 md:pt-6 md:pb-10 md:pl-11 md:pr-7'
        )}
      >
        <div>
          <p className="text-body-sm mb-3">
            Topic {position} of {videoListLength}
          </p>
          <h2 className="u-h4 mb-4">{title}</h2>
          <p className="text-body-md">{description}</p>
        </div>
        {nextVideoLink !== null && (
          <div>
            <Button
              className="text-white mobile+tablet:mt-6"
              variant="quaternary"
              as="a"
              href={nextVideoLink}
              label="Next topic"
            />
          </div>
        )}
      </div>
    </div>
  );
};

VideoPlayerCard.defaultProps = {
  nextVideoLink: null,
};

VideoPlayerCard.propTypes = {
  wistiaId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  videoListLength: PropTypes.number.isRequired,
  nextVideoLink: PropTypes.string,
};

export default VideoPlayerCard;
